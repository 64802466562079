'use client';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import Cookies from 'js-cookie';

import JsonView from '@/components/JsonView';
import * as Styled from './styled';

const DebugJsonView: React.FC<{ rootNodeName?: string; json: any; collapseDepth?: number }> = ({
  rootNodeName,
  json,
  collapseDepth = 0,
  ...rest
}) => {
  const debugCookie = Cookies.get('oxen_debug_mode');
  const [debugMode, setDebugMode] = useState<string | null>();
  const debugModeSwitch = useSearchParams()?.get('debug');

  useEffect(() => {
    setDebugMode(debugCookie);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debugModeSwitch) {
      setDebugMode(debugModeSwitch);
      Cookies.set('oxen_debug_mode', debugModeSwitch);
    }
  }, [debugModeSwitch]);

  return debugMode === 'true' ? (
    <Styled.DebugJson {...rest}>
      <JsonView rootNodeName={rootNodeName} json={json} collapseDepth={collapseDepth} />
    </Styled.DebugJson>
  ) : null;
};

const DebugJson: React.FC<{ rootNodeName?: string; json: any; collapseDepth?: number }> = ({
  rootNodeName,
  json,
  collapseDepth,
}) => {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }
  return <DebugJsonView rootNodeName={rootNodeName} json={json} collapseDepth={collapseDepth} />;
};

export default DebugJson;
