'use client';
import React from 'react';
import Tag from '@/ui/Tag';
import * as Styled from './styled';
import { useModal } from 'react-hooks-use-modal';
import PlanOptions from '@/components/PlanOptions';
import * as Icon from '@/ui/Icon';

import { ROOT_LAYOUT_DOM_NODE_ID } from '@/config/appSettings';
import { IPlanOption } from '@/config/planOptions';
import { IUser, IMemberPlan } from '@/types';
import { Stack } from '@/ui/primatives';

const PlanUpgradeLink: React.FC<{ currentUser: IUser; currentPlan: IMemberPlan; availablePlans: IPlanOption[] }> = ({
  currentUser,
  currentPlan,
  availablePlans,
}) => {
  const [Modal, open, close, isOpen] = useModal(ROOT_LAYOUT_DOM_NODE_ID, {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  });

  return (
    <>
      <a href="#" onClick={open}>
        <Tag variant="info" border>
          Upgrade
        </Tag>
      </a>

      <Modal>
        <Styled.UpgradeModal>
          <Styled.ModalClose>
            <Icon.X size="24px" onClick={close} />
          </Styled.ModalClose>
          <Stack.Centered gap={10}>
            <h4>Upgrade your plan</h4>
            <p>Purchase an annual contract and save 20%!</p>
          </Stack.Centered>
          <PlanOptions
            currentUser={currentUser}
            availablePlans={availablePlans}
            currentPlan={currentPlan}
            onSelect={close}
          />
          <p>
            Non-Profit & Academic organizations please{' '}
            <a className="link" href="mailto:hello@oxen.ai">
              contact us
            </a>{' '}
            for discounting.
          </p>
        </Styled.UpgradeModal>
      </Modal>
    </>
  );
};

export default PlanUpgradeLink;
