import * as Styled from './styled';
import FormFieldWrapper from '../FormFieldWrapper';
import { Control, FieldError, UseFormRegister } from 'react-hook-form';

interface InputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  value?: string | number;
  label?: string;
  labelSupplement?: React.ReactNode;
  hint?: string;
  wide?: boolean;
  error?: FieldError | null;
  success?: boolean | null;
  submit?: UseFormRegister<any>; // declare submit props
  control?: Control;
  disabled?: boolean;
  rows?: number;
}

const Textarea: React.FC<InputProps> = ({
  name,
  label,
  labelSupplement,
  hint,
  wide = false,
  error,
  success,
  submit,
  control,
  disabled,
  rows = 5,
  ...props
}) => {
  return (
    <FormFieldWrapper
      error={error}
      label={label}
      labelSupplement={labelSupplement}
      hint={hint}
      success={success}
      wide={wide}
    >
      <Styled.TextareaWrapper $error={!!error} $success={!!success && !error} disabled={!!disabled}>
        {submit ? (
          <Styled.Textarea
            $error={!!error}
            $success={!!success && !error}
            disabled={disabled}
            rows={rows}
            {...submit(name)}
            {...props}
          />
        ) : (
          <Styled.Textarea
            $error={!!error}
            $success={!!success}
            name={name}
            disabled={disabled}
            rows={rows}
            {...props}
          />
        )}
      </Styled.TextareaWrapper>
    </FormFieldWrapper>
  );
};

export default Textarea;
