import styled from 'styled-components';
import { colors } from '@/styles';

const stateTextColor = (props: any) =>
  props.$error
    ? colors.semantic.negative.dark
    : props.$success
    ? colors.semantic.positive.dark
    : colors.semantic.placeholder;

export const Label = styled.label<{ $error: boolean; $success: boolean; $wide: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${(props) => (props.$wide ? '100%' : '500px')};
  width: ${(props) => (props.$wide ? '100%' : 'auto')};
  border-radius: 5px;
  gap: 10px;

  & > div {
    color: ${colors.primary.gray};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
      display: block;
    }
  }

  & button {
    /* padding-top: 11px;
    padding-bottom: 11px; */
    height: 41px;
  }

  .input-hint {
    margin: 0 5px;
    font-size: 12px;
    color: ${colors.semantic.placeholder};
  }

  .input-optional {
    font-size: 14px;
    color: ${(props) => stateTextColor(props)};
  }
`;
