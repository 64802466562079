import React, { useState } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormFieldWrapper from '../FormFieldWrapper';
import * as Styled from './styled';

import { SelectProps, SelectControlProps } from './';

import { Controller } from 'react-hook-form';

const CreateableSelect: React.FC<SelectProps> = ({
  selectedValue,
  options,
  customComponents,
  prefixElement,
  isSearchable = true,
  isClearable = false,
  menuIsOpen,
  name,
  label,
  error,
  success,
  disabled,
  hint,
  onChange,
  control,
  classNames,
  styles,
  placeholder,
  ...props
}) => {
  return (
    <FormFieldWrapper error={error} label={label} hint={hint} success={success} {...props}>
      {!!control ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <CreateableSelectControl
                error={error}
                selectedValue={value}
                options={options}
                customComponents={customComponents}
                prefixElement={prefixElement}
                isSearchable={isSearchable}
                isClearable={isClearable}
                menuIsOpen={menuIsOpen}
                name={name}
                success={success}
                onChange={(option) => onChange(option || '')}
                styles={styles}
                classNames={classNames}
                placeholder={placeholder}
                disabled={disabled}
              />
            );
          }}
        />
      ) : (
        <CreateableSelectControl
          error={error}
          selectedValue={selectedValue}
          options={options}
          customComponents={customComponents}
          prefixElement={prefixElement}
          isSearchable={isSearchable}
          isClearable={isClearable}
          name={name}
          success={success}
          onChange={(option) => onChange?.(option)}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
      )}
    </FormFieldWrapper>
  );
};

type Option = {
  readonly label: string;
  readonly value: string;
};

const CreateableSelectControl: React.FC<SelectControlProps> = ({
  selectedValue,
  options: initialOptions,
  customComponents,
  prefixElement,
  isSearchable = true,
  menuIsOpen,
  isClearable,
  name,
  error,
  success,
  disabled,
  onChange,
  placeholder,
  ref,
  wide,
  classNames,
  styles,
  ...props
}) => {
  const [options, setOptions] = useState(initialOptions);
  const initialValue = options.find((option) => option.value === selectedValue) || null;
  const [value, setValue] = useState<Option | null>(initialValue);

  const createOption = (value: string) => ({
    label: value,
    value: value,
  });

  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue.trim());
    const isDuplicateOptionValue = !!options.find((opt) => opt.value === newOption.value);
    if (!isDuplicateOptionValue) {
      setOptions((prev) => [...prev, newOption]);
    }
    setValue(newOption);
    onChange(newOption.value);
  };

  const handleOnChange = (selected: Option, actionMeta: any) => {
    if (actionMeta.action === 'clear') {
      setValue(null);
    } else {
      setValue(selected);
    }
    onChange(selected?.value);
  };

  let tmpCreateOptionValue = '';

  const handleOnInputChange = (input: string, actionMeta: any) => {
    if (actionMeta.action === 'input-change') {
      tmpCreateOptionValue = input.trim();
    }

    if (['input-blur'].includes(actionMeta.action)) {
      if (!tmpCreateOptionValue) {
        return;
      }
      handleCreate(tmpCreateOptionValue);
      tmpCreateOptionValue = '';
    }
  };

  return (
    <Styled.Select error={error} success={success} $wide={wide}>
      <CreatableSelect
        className="oxen-select"
        classNamePrefix="oxen-select"
        isSearchable={isSearchable}
        isDisabled={disabled}
        isOptionDisabled={(option) => option.disabled}
        isClearable={isClearable}
        options={options}
        menuIsOpen={menuIsOpen}
        name={name}
        placeholder={placeholder}
        instanceId={name}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        onCreateOption={handleCreate}
        classNames={classNames}
        styles={styles}
        components={{
          ValueContainer: ({ children, ...props }) => {
            return (
              <components.ValueContainer {...props}>
                <Styled.ValueWrapper>
                  {prefixElement}
                  {children}
                </Styled.ValueWrapper>
              </components.ValueContainer>
            );
          },
          IndicatorSeparator: () => null,
          ...customComponents,
        }}
        value={value}
        {...props}
      />
    </Styled.Select>
  );
};

export default CreateableSelect;
export { CreateableSelectControl };
