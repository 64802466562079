'use client';
// import ReactJsonView from '@microlink/react-json-view';

import dynamic from 'next/dynamic';
const ReactJsonView = dynamic(() => import('@microlink/react-json-view'), {
  ssr: false,
});

const tryParseJSONObject = (jsonString: string) => {
  try {
    var o = JSON.parse(JSON.stringify(jsonString));
    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
};

const JsonView: React.FC<{
  rootNodeName?: string | false;
  json: any;
  collapseDepth?: number;
  groupArraysAfterLength?: number;
}> = ({ rootNodeName = false, json, collapseDepth, groupArraysAfterLength }) => {
  const parsedJSON = tryParseJSONObject(json);
  return parsedJSON ? (
    <>
      <ReactJsonView
        name={rootNodeName}
        src={parsedJSON}
        collapsed={collapseDepth}
        groupArraysAfterLength={groupArraysAfterLength}
      />
    </>
  ) : (
    <ReactJsonView
      // style={{
      //   backgroundColor: 'pink',
      // }}
      name={rootNodeName}
      src={{ json: 'Error: Not a valid JSON object' }}
      collapsed={collapseDepth}
      groupArraysAfterLength={groupArraysAfterLength}
    />
    // <div>Not a valid JSON object</div>
  );
};

export default JsonView;
