import React from 'react';
import * as Styled from './styled';

interface FormFieldWrapperProps {
  error?: any;
  success?: boolean | null;
  label?: string;
  labelSupplement?: React.ReactNode;
  hint?: string;
  wide?: boolean;
  children: React.ReactNode;
}

const FormFieldWrapper: React.FC<React.HtmlHTMLAttributes<HTMLLabelElement> & FormFieldWrapperProps> = ({
  error,
  success,
  label,
  labelSupplement,
  hint,
  children,
  wide = false,
  ...rest
}) => (
  <Styled.Label $error={!!error} $success={!!success} $wide={!!wide} {...rest}>
    <div>
      <span className="input-label">{label}</span>
      <span className="input-optional">{error?.message || labelSupplement || '\u00A0'}</span>
    </div>
    {children}
    {hint && (
      <div>
        <span className="input-hint">{hint}</span>
      </div>
    )}
  </Styled.Label>
);

export default FormFieldWrapper;
