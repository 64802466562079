import styled from 'styled-components';
import { colors } from '@/styles';

const stateBorderColor = (props: any) =>
  props.$error
    ? colors.semantic.negative.dark
    : props.$success
    ? colors.semantic.positive.dark
    : colors.secondary.lightGray2;

export const InputWrapper = styled.span<{
  $error: boolean;
  $success: boolean;
  disabled: boolean;
  $buttonText?: string;
}>`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
  /* padding: 5px 10px; */
  /* border-radius: 5px; */
  /* border: 1px solid ${colors.secondary.lightGray2}; */
  width: 100%;
  /* border-color: ${(props) => stateBorderColor(props)}; */
  /* background: ${({ disabled }) => (disabled ? colors.secondary.lightGray1 : 'white')}; */

  &:focus-within {
    border-color: ${colors.secondary.blue2};
    outline: none;
  }

  input[type='file'] {
    position: relative;

    &::before {
      position: absolute;
      pointer-events: none;
      top: 10px;
      left: 16px;
      height: 20px;
      width: 20px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23161616'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
    }
    &::after {
      position: absolute;
      pointer-events: none;
      top: 11px;
      left: 40px;
      color: ${colors.primary.gray};
      content: '${(props) => props.$buttonText || 'Upload File'}';
    }
    &:hover::after {
      text-decoration: underline;
    }

    &::file-selector-button {
      width: 136px;
      color: transparent;
      border-radius: 4px;
      padding: 0 16px;
      height: 40px;
      cursor: pointer;
      background-color: ${colors.secondary.lightGray2};
      border: 1px solid ${colors.secondary.lightGray3};
      margin-right: 16px;
      transition: background-color 200ms;
      &:hover {
        background-color: ${colors.secondary.lightGray3};
        text-decoration: underline;
      }
      &:active {
        background-color: ${colors.secondary.lightGray3};
      }
    }
  }
`;

export const Input = styled.input<{ $error: boolean; $success: boolean }>`
  &:focus {
    border: none;
    outline: none;
  }
  width: 100%;
  border: none;
  padding: 0;
`;
