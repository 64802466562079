'use client';
import { IUser } from '@/types';
import { Row } from '@/ui/primatives';
import * as Icon from '@/ui/Icon';
import { colors } from '@/styles';

const TestError: React.FC<{ user: IUser }> = ({ user }) => {
  return (
    <div
      style={{ padding: '5px 0', color: colors.semantic.negative.primary }}
      onClick={() => {
        throw new Error(`Test error from: ${user.username}`);
      }}
    >
      <Row gap={10} alignItems="center">
        <Icon.ExclamationCircle />
        <span>Throw test error</span>
      </Row>
    </div>
  );
};

export default TestError;
