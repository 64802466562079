import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { FOCUS_THROTTLE_INTERVAL } from '@/config/appSettings';

import { IResponseError, getTokenFromCookie } from '@/lib/apiBase';
import { fetchUserCredits, TNamespaceCredit, IUserCreditsResponse } from '@/lib/userCreditsApi';

const REFRESH_INTERVAL = 30 * 1000; // 30 seconds

const useNamespaceCredits = (initialData?: IUserCreditsResponse) => {
  const [namespaceCredits, setNamespaceCredits] = useState<TNamespaceCredit | undefined>(initialData?.namespace_credit);
  const [error, setError] = useState<IResponseError | undefined>();
  const token = getTokenFromCookie();

  const {
    data,
    error: requestError,
    isValidating,
  } = useSWR(`namespace-credits-${token}`, () => fetchUserCredits(token), {
    keepPreviousData: true,
    focusThrottleInterval: FOCUS_THROTTLE_INTERVAL,
    refreshInterval: REFRESH_INTERVAL,
    fallbackData: initialData,
  });

  const maybeCastToNumber = (value?: string | number): number => {
    if (typeof value === 'string') {
      return parseFloat(value);
    }
    if (value === undefined || value === null) {
      return 0;
    }
    return value;
  };

  useEffect(() => {
    if (data) {
      setNamespaceCredits(data.namespace_credit);
      setError(requestError as IResponseError);
    }
  }, [data, requestError]);

  const loading: boolean = isValidating && !namespaceCredits;

  return {
    loading,
    namespaceCredits: {
      ...namespaceCredits,
      available_credits: maybeCastToNumber(namespaceCredits?.available_credits),
      isEmpty: namespaceCredits?.available_credits == null || namespaceCredits?.available_credits === undefined,
    },
    error,
  };
};

export default useNamespaceCredits;
