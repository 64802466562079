'use client';
import React from 'react';
import { Identity } from '@/hooks/useIdentity';
import { UserAvatar } from '@/components/Avatar';
import * as Styled from './styled';
import { IUser } from '@/types';
import { Row } from '@/ui/primatives';
import Link from 'next/link';

const UserInfo: React.FC<{ user: IUser; children?: React.ReactNode }> = ({ user, children, ...props }) => {
  const { user: currentUser } = Identity.useContainer();

  const activeUser = currentUser?.id === user.id ? currentUser : user;
  return (
    <Row gap={10} justifyContent="space-between">
      <Styled.UserInfo {...props}>
        <UserAvatar user={activeUser} size={50} />
        <Styled.Content>
          <Link href={`/${activeUser.username}`}>
            <h5>@{activeUser.username}</h5>
          </Link>
          <div>{activeUser.name}</div>
        </Styled.Content>
      </Styled.UserInfo>
      {children}
    </Row>
  );
};

export default UserInfo;
