'use client';
import React, { useState } from 'react';
import Input from '@/components/Input';
import { IUser } from '@/types';
import tracking from '@/lib/tracking';

import useClipboard from '@/hooks/useClipboard';

import * as Icon from '@/ui/Icon';

const ApiKeyWidget: React.FC<{ user?: IUser; label?: string; wide?: boolean }> = ({
  user,
  label = 'API Key',
  wide,
}) => {
  const [apiKeyIsShowing, setApiKeyIsShowing] = useState(false);

  let apiKey = user?.api_key || 'INVALID_API_KEY';

  const handleShowApiKey = () => {
    setApiKeyIsShowing(!apiKeyIsShowing);
  };

  const trackEvent = () => {
    tracking.capture('api_key_copied', {
      user_id: user?.id,
      username: user?.username,
    });
  };

  const [copiedText, copy] = useClipboard(apiKey, { afterClick: trackEvent });

  return (
    <div>
      <Input
        name="api_key"
        type={apiKeyIsShowing ? 'text' : 'password'}
        value={apiKey}
        label={label}
        wide={wide}
        readOnly={true}
        suffixElement={
          <>
            {apiKeyIsShowing ? (
              <Icon.EyeOpen onClick={handleShowApiKey} />
            ) : (
              <Icon.EyeClosed onClick={handleShowApiKey} />
            )}
            {!!copiedText ? <Icon.ClipboardTick onClick={copy} /> : <Icon.ClipboardPlus onClick={copy} />}
          </>
        }
      />
    </div>
  );
};

export default ApiKeyWidget;
