import React, { HTMLInputTypeAttribute } from 'react';

import * as Styled from '../styled';
import FormFieldWrapper from '../FormFieldWrapper';
import { Control, FieldError, UseFormRegister } from 'react-hook-form';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  label?: string;
  labelSupplement?: React.ReactNode;
  prefixElement?: React.ReactNode;
  suffixElement?: React.ReactNode;
  hint?: string;
  wide?: boolean;
  nowrap?: boolean;
  error?: FieldError | null;
  success?: boolean | null;
  submit?: UseFormRegister<any>; // declare submit props
  control?: Control;
  disabled?: boolean;
}

const Checkbox: React.FC<InputProps> = ({
  type = 'text',
  name,
  value,
  label,
  labelSupplement,
  prefixElement,
  suffixElement,
  hint,
  wide = false,
  nowrap = false,
  error,
  success,
  submit,
  control,
  disabled,
  ...props
}) => {
  return (
    <Styled.CheckboxItem>
      {/* <FormFieldWrapper
      error={error}
      label={label}
      labelSupplement={labelSupplement}
      hint={hint}
      success={success}
      wide={wide}
    > */}
      {submit ? (
        <Styled.Input
          $error={!!error}
          $success={!!success && !error}
          disabled={disabled}
          type="checkbox"
          value={value}
          {...submit(name)}
          {...props}
        />
      ) : (
        <Styled.Input
          $error={!!error}
          $success={!!success}
          type="checkbox"
          name={name}
          value={value}
          disabled={disabled}
          {...props}
        />
      )}
      <span style={{ whiteSpace: nowrap ? 'nowrap' : 'inherit' }}>{label}</span>
      {/* </FormFieldWrapper> */}
    </Styled.CheckboxItem>
  );
};

export const CheckboxGroup: React.FC<
  InputProps & { cols?: number; options: { label: string; value: string }[]; children?: React.ReactNode }
> = ({
  type = 'text',
  name,
  label,
  labelSupplement,
  prefixElement,
  suffixElement,
  hint,
  wide = false,
  error,
  success,
  submit,
  control,
  disabled,
  cols = 1,
  options,
  children,
  ...props
}) => {
  return (
    <FormFieldWrapper label={label} hint={hint || undefined} error={error}>
      <Styled.OptionGroup $cols={cols}>
        {options.map((option) => (
          <Checkbox
            key={option.label}
            name={name}
            value={option.value}
            label={option.label}
            submit={submit}
            {...props}
          />
        ))}
      </Styled.OptionGroup>
      {children}
    </FormFieldWrapper>
  );
};

export default Checkbox;
