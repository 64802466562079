import React, { HTMLInputTypeAttribute } from 'react';

import * as Styled from './styled';
import FormFieldWrapper from './FormFieldWrapper';
import { Control, FieldError, UseFormRegister } from 'react-hook-form';

import Textarea from './Textarea';
import File from './File';
import Select, { SelectControl } from './Select';
import CreateableSelect, { CreateableSelectControl } from './Select/CreateableSelect';
import Checkbox, { CheckboxGroup } from './Checkbox';
import RadioButton, { RadioButtonGroup } from './RadioButton';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  label?: string;
  labelSupplement?: React.ReactNode;
  prefixElement?: React.ReactNode;
  suffixElement?: React.ReactNode;
  hint?: string;
  wide?: boolean;
  error?: FieldError | null;
  success?: boolean | null;
  submit?: UseFormRegister<any>; // declare submit props
  control?: Control;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  name,
  label,
  labelSupplement,
  prefixElement,
  suffixElement,
  hint,
  wide = false,
  error,
  success,
  submit,
  control,
  disabled,
  ...props
}) => {
  return (
    <FormFieldWrapper
      error={error}
      label={label}
      labelSupplement={labelSupplement}
      hint={hint}
      success={success}
      wide={wide}
    >
      <Styled.InputWrapper $error={!!error} $success={!!success && !error} disabled={!!disabled}>
        {prefixElement}
        {submit ? (
          <Styled.Input
            $error={!!error}
            $success={!!success && !error}
            disabled={disabled}
            type={type}
            {...submit(name)}
            {...props}
          />
        ) : (
          <Styled.Input $error={!!error} $success={!!success} type={type} name={name} disabled={disabled} {...props} />
        )}
        {suffixElement}
      </Styled.InputWrapper>
    </FormFieldWrapper>
  );
};

const ComposedInput = Object.assign(Input, {
  Textarea,
  Checkbox,
  CheckboxGroup,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectControl,
  CreateableSelect,
  CreateableSelectControl,
  FormFieldWrapper,
  File,
});
export default ComposedInput;
