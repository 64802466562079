import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Registration = styled.div.attrs((props) => ({}))`
  display: inline-flex;
  /* background: ${colors.primary.white}; */
  border-radius: 20px;
  width: 470px;
  z-index: 1;
  @media (max-width: ${breakPoints.mobile}) {
    width: auto;
  }
`;
