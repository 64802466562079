import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const BillingCycleSwitch = styled.div<{ $glow?: boolean; $borderWidth?: number }>`
  --border-angle: 0turn;

  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5000px;
  z-index: 0;

  & > * {
    width: 100%;
    position: relative;
    /* min-height: 45px; */
    border-radius: 5000px;
    border: none;
    padding: 5px;
    background: white;

    @media (max-width: ${breakPoints.mobile}) {
      button {
        padding: 15px;
        white-space: nowrap;
      }
    }
  }

  --clr-1: #f87a7a;
  --clr-2: #f9947d;
  --clr-3: #f57ab1;
  --clr-4: #e07de8;
  --clr-5: #dbb2ed;
  --clr-6: #efb4c9;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: -${(props) => props.$borderWidth || 3}px;
    z-index: -1;

    background: conic-gradient(
      from var(--border-angle),
      var(--clr-1),
      var(--clr-2),
      var(--clr-3),
      var(--clr-4),
      var(--clr-5),
      var(--clr-6),
      var(--clr-1)
    );
    border-radius: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 2s linear infinite;
  }

  &:after {
    filter: ${(props) => (props.$glow ? 'blur(2rem)' : 'none')};
    opacity: 0.5;
  }

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  &:hover {
    animation-play-state: paused;
  }
  @property --border-angle {
    syntax: '<angle>';
    inherits: true;
    initial-value: 0turn;
  }
`;
