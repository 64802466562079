'use client';
import styled from 'styled-components';
import { colors, mixins, breakPoints } from '@/styles';

export const Modal = styled.div`
  position: relative;
  background: #fff;
  color: #000;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem; /* Space between elements */
  z-index: 1;
  max-width: 600px;
  padding-top: 40px;

  font-size: 16px;

  h4 {
    font-family: var(--font-bwseido-raw);
    text-transform: uppercase;
    ${mixins.animatedGradientText()}
  }

  @media (max-width: ${breakPoints.mobile}) {
    position: fixed;
    border-radius: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 80px 20px 0;
  }
`;

export const ModalClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  &:hover {
    stroke-width: 2px;
  }
`;
