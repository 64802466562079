'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const PlanInfo = styled.div<{}>`
  display: flex;
  padding: 25px 50px;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  background-color: ${colors.semantic.information.light};
  color: ${colors.semantic.information.dark};

  & > .plan-name {
    text-transform: uppercase;
  }

  @media (max-width: ${breakPoints.mobile}) {
    display: none;
  }
`;

export const UpgradeModal = styled.div<{}>`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background: ${colors.primary.white};
  padding: 25px;
  max-width: 75vw;
  gap: 25px;

  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ModalClose = styled.div<{}>`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  &:hover {
    stroke-width: 2px;
  }
`;
