'use client';
import React from 'react';

import Button from '@/ui/Button';
import { IOrganization } from '@/types';

const CreateRepositoryButton: React.FC<{ organization?: IOrganization; children?: React.ReactNode }> = ({
  organization,
  children,
}) => {
  const linkPath: string = organization ? `/organizations/${organization.name}/repositories/new` : '/repositories/new';
  return (
    <Button.Black className="hide-mobile" href={linkPath} testId="create-repo" nowrap>
      {children || 'Create a new repository'}
    </Button.Black>
  );
};

export default CreateRepositoryButton;
