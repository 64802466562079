import styled from 'styled-components';
import { colors } from '@/styles';

const stateBorderColor = (props: any) =>
  props.$error
    ? colors.semantic.negative.dark
    : props.$success
    ? colors.semantic.positive.dark
    : colors.secondary.lightGray2;

export const TextareaWrapper = styled.span<{ $error: boolean; $success: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid ${colors.secondary.lightGray2};
  width: 100%;
  border-color: ${(props) => stateBorderColor(props)};
  background: ${({ disabled }) => (disabled ? colors.secondary.lightGray1 : 'white')};

  &:focus-within {
    border-color: ${colors.secondary.blue2};
    outline: none;
  }
`;

export const Textarea = styled.textarea<{ $error: boolean; $success: boolean }>`
  &:focus {
    border: none;
    outline: none;
  }
  width: 100%;
  border: none;
  border-radius: 5px;
  /* border: 1px solid ${colors.secondary.lightGray2}; */
  padding: 5px;
`;
