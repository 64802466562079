'use client';
import React from 'react';
import Cookies from 'js-cookie';
import { IUser } from '@/types';
import * as Styled from './styled';
import { fetchPlans } from '@/lib/plansApi';
import { fetchUserCredits } from '@/lib/userCreditsApi';

import * as format from '@/lib/format';
import PlanUpgradeLink from './PlanUpgradeLink';
import { IS_RUNNING_ON_PREMISES } from '@/config/environment';
import useNamespaceCredits from '@/hooks/useNamespaceCredits';
import { IPlanOption } from '@/config/planOptions';

// Format the price above to USD using the locale, style, and currency.
const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const maybeCastToNumber = (value?: string | number): number | undefined => {
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value;
};

const PlanInfo: React.FC<{ user: IUser; plans: IPlanOption[] }> = ({ user, plans }) => {
  const { namespaceCredits } = useNamespaceCredits();
  const currentPlan = user?.plan;
  const storageUsed = format.humanFileSize((user.storage_usage_gb || 0) * format.GIGABYTE);
  const storageAvailable = format.humanFileSize((currentPlan?.storage_quota_gb || 0) * format.GIGABYTE);

  const availableCredits = namespaceCredits?.available_credits;
  const isEmpty = namespaceCredits?.isEmpty;
  // TODO: replace the avalailable credits with a shared compoonent with AvaalableCreditsBanner

  return !IS_RUNNING_ON_PREMISES
    ? plans && currentPlan && (
        <Styled.PlanInfo>
          <div className="plan-name">{currentPlan?.name} Plan</div>
          {!isEmpty && (
            <div>
              <b>{USDollar.format(availableCredits)} compute</b> credits remaining
            </div>
          )}
          <div>
            <b>
              {storageUsed}/{storageAvailable}
            </b>{' '}
            storage used
          </div>
          <PlanUpgradeLink currentUser={user} currentPlan={currentPlan} availablePlans={plans} />
        </Styled.PlanInfo>
      )
    : null;
};

export default PlanInfo;
