import { useState, useEffect } from 'react';

const useReferralCode = (initialCode?: string) => {
  const [referralCode, setReferralCode] = useState<string | undefined>(undefined);

  useEffect(() => {
    const isClient = typeof window !== 'undefined';
    const storedCode = isClient ? localStorage.getItem('referralCode') : undefined;

    if (initialCode) {
      setReferralCode(initialCode);
      if (isClient) localStorage.setItem('referralCode', initialCode);
    } else if (storedCode) {
      setReferralCode(storedCode);
    }
  }, [initialCode]);

  const updateReferralCode = (newCode: string) => {
    setReferralCode(newCode);
    if (typeof window !== 'undefined') {
      localStorage.setItem('referralCode', newCode);
    }
  };

  return { referralCode, setReferralCode: updateReferralCode } as const;
};

export default useReferralCode;
