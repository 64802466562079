'use client';
import styled from 'styled-components';

export const DebugJson = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 1280px;
  margin: 20px auto;
  .variable-row {
    white-space: nowrap;
  }
`;
