'use client';
import styled from 'styled-components';
import { colors } from '@/styles';

export const UserSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  svg {
    display: block;
  }

  a.active {
    background-color: ${colors.secondary.lightGray2};
    font-weight: bold;
  }
`;

export const SettingsLink = styled.div`
  padding-top: 5px;
  svg:hover {
    path:nth-child(1) {
      fill: ${colors.secondary.lightGray2};
    }
    path:nth-child(2) {
      fill: white;
    }
  }
`;
