'use client';
import Link from 'next/link';
import { IUser } from '@/types';
import * as Styled from './styled';
import { ROOT_LAYOUT_DOM_NODE_ID } from '@/config/appSettings';
import { useModal } from 'react-hooks-use-modal';
import useClipboard from '@/hooks/useClipboard';

import Button from '@/ui/Button';
import { Card } from '@/ui/primatives';
import * as Icon from '@/ui/Icon';
import Confetti from '@/ui/Confetti';
import tracking from '@/lib/tracking';

const ReferralButton: React.FC<{ currentUser: IUser; children?: React.ReactNode; quiet?: boolean }> = ({
  currentUser,
  children,
  quiet,
}) => {
  const [Modal, open, close, isOpen] = useModal(ROOT_LAYOUT_DOM_NODE_ID, {
    preventScroll: true,
  });

  const trackEvent = () => {
    tracking.capture(`referral_link_copied`, {
      user_id: currentUser.id,
      username: currentUser.username,
    });
  };

  const referralLinkText = `oxen.ai/referral/${currentUser.username}`;
  const referralLink = `https://${referralLinkText}`;

  const [copiedText, copy] = useClipboard(referralLink, { afterClick: trackEvent });

  return (
    <>
      {quiet ? (
        <Button.PrimaryOutline small onClick={open}>
          {children || 'Get $5 in credit for each referral'}
        </Button.PrimaryOutline>
      ) : (
        <Button.Primary small onClick={open}>
          {children || 'Get $5 in credit for each referral'}
        </Button.Primary>
      )}
      {isOpen && (
        <Modal>
          <Styled.Modal>
            <Styled.ModalClose>
              <Icon.X size="24px" onClick={close} />
            </Styled.ModalClose>

            <h4>Give $5 == Get $5</h4>
            <p>Share your referral link, for each referral both of you will recieve a bonus $5 in compute credits.</p>

            <Card.Info>
              <h5 onClick={copy} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 10 }}>
                <Icon.Copy size="26px" />
                {referralLinkText}
              </h5>
            </Card.Info>
            <Link href="https://docs.oxen.ai/getting-started/models" className="link-underline" target="_blank">
              Learn more about Oxen Evaluations
            </Link>
          </Styled.Modal>
          <Confetti density={500} zIndex={0} />
        </Modal>
      )}
    </>
  );
};

export default ReferralButton;
