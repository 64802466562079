import styled from 'styled-components';
import { colors } from '@/styles';

const stateBorderColor = (props: any) =>
  props.error
    ? colors.semantic.negative.dark
    : props.success
      ? colors.semantic.positive.dark
      : colors.secondary.lightGray2;

export const ValueWrapper = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const Select = styled.div<{
  error: any;
  success: any;
  $wide?: boolean;
}>`
  width: ${(props) => (props.$wide ? '100%' : 'auto')};

  .oxen-select {
    width: 100%;
    min-width: 80px;
  }
  .oxen-select__control {
    padding: 0 7px;
    border-radius: 5px;
    border-color: ${(props) => stateBorderColor(props)};
  }
  .oxen-select__value-container {
    padding: 0;
  }
  .oxen-select__indicator {
    padding: 0;
  }
`;
