import { fetchWrapper, IBaseResponse } from './apiBase';
import { BASE_API_URL } from '@/config/environment';

export type TNamespaceCredit = {
  available_credits: string | number;
  isEmpty: boolean;
  id: string;
  inserted_at: string;
  updated_at: string;
  user_id: string;
};

export interface IUserCreditsResponse extends IBaseResponse {
  namespace_credit: TNamespaceCredit;
}

export async function fetchUserCredits(token?: string): Promise<IUserCreditsResponse> {
  const targetUrl = `${BASE_API_URL}/user/credits`;

  return await fetchWrapper({
    resourceName: 'user_credits',
    targetUrl,
    authToken: token,
    resourceContext: { hasToken: !!token },
  });
}
