'use client';
import styled from 'styled-components';

export const UserInfo = styled.div`
  display: flex;
  gap: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
