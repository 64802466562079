import React, { HTMLInputTypeAttribute } from 'react';

import FormFieldWrapper from '../FormFieldWrapper';
import * as Styled from '../styled';
import { Control, FieldError, UseFormRegister } from 'react-hook-form';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  label?: string;
  labelSupplement?: React.ReactNode;
  prefixElement?: React.ReactNode;
  suffixElement?: React.ReactNode;
  hint?: string;
  wide?: boolean;
  error?: FieldError | null;
  success?: boolean | null;
  submit?: UseFormRegister<any>; // declare submit props
  control?: Control;
  disabled?: boolean;
}

const RadioButton: React.FC<InputProps> = ({
  type = 'text',
  value,
  name,
  label,
  labelSupplement,
  prefixElement,
  suffixElement,
  hint,
  wide = false,
  error,
  success,
  submit,
  control,
  disabled,
  ...props
}) => {
  return (
    <Styled.RadioButtonItem>
      {submit ? (
        <Styled.Input
          $error={!!error}
          $success={!!success && !error}
          disabled={disabled}
          type="radio"
          value={value}
          {...submit(name)}
          {...props}
        />
      ) : (
        <Styled.Input
          $error={!!error}
          $success={!!success}
          type="radio"
          name={name}
          value={value}
          disabled={disabled}
          {...props}
        />
      )}
      <span>{label}</span>
    </Styled.RadioButtonItem>
  );
};

export const RadioButtonGroup: React.FC<
  InputProps & { cols?: number; options: { label: string; value: string }[]; children?: React.ReactNode }
> = ({
  type = 'text',
  name,
  label,
  labelSupplement,
  prefixElement,
  suffixElement,
  hint,
  wide = false,
  error,
  success,
  submit,
  control,
  disabled,
  cols = 1,
  options,
  children,
  ...props
}) => {
  return (
    <FormFieldWrapper label={label} hint={hint || undefined}>
      <Styled.OptionGroup $cols={cols}>
        {options.map((option) => (
          <RadioButton
            key={option.label}
            name={name}
            label={option.label}
            value={option.value}
            submit={submit}
            {...props}
          />
        ))}
      </Styled.OptionGroup>
      {children}
    </FormFieldWrapper>
  );
};

export default RadioButton;
