'use client';
import { CreateTypes } from 'canvas-confetti';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import styled from 'styled-components';

const StyledConfettiWrapper = styled(ReactCanvasConfetti)<{ $zIndex?: number }>`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ zIndex }) => (typeof zIndex === undefined ? -1 : zIndex)};
`;

const Confetti: React.FC<{ density?: number; zIndex?: number }> = ({ density = 300, zIndex }) => {
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback(
    (particleRatio: number, opts: object) => {
      refAnimationInstance.current &&
        refAnimationInstance.current({
          ...opts,
          origin: { y: 0.55 },
          particleCount: Math.floor(density * particleRatio),
        });
    },
    [density],
  );

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
    // console.log('done');
  }, [makeShot]);

  useEffect(() => fire(), [fire]);

  return (
    <StyledConfettiWrapper
      zIndex={zIndex}
      refConfetti={getInstance}
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }}
    />
  );
};

export const ConfettiDemo: React.FC<{}> = ({}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div style={{ width: '100%', height: 500 }}>
      <button onClick={() => setIsVisible(true)}>Fire</button>
      {isVisible && <Confetti density={500} />}
    </div>
  );
};

export default Confetti;
