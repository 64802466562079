import styled from 'styled-components';
import { colors } from '@/styles';

const stateBorderColor = (props: any) =>
  props.$error
    ? colors.semantic.negative.dark
    : props.$success
      ? colors.semantic.positive.dark
      : colors.secondary.lightGray2;

export const InputWrapper = styled.span<{ $error: boolean; $success: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  min-height: 40px;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid ${colors.secondary.lightGray2};
  width: 100%;
  border-color: ${(props) => stateBorderColor(props)};
  background: ${({ disabled }) => (disabled ? colors.secondary.lightGray1 : 'white')};

  &:focus-within {
    border-color: ${colors.secondary.blue2};
    outline: none;
  }
`;

export const Input = styled.input<{ $error: boolean; $success: boolean }>`
  &:focus {
    border: none;
    outline: none;
  }
  width: 100%;
  border: none;
  padding: 0;
`;

export const CheckboxItem = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;

  & > * {
    flex: 1;
    &:first-child {
      flex: 0;
      min-width: 1rem;
    }
  }
`;

export const RadioButtonItem = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;

  & > * {
    flex: 1;
    &:first-child {
      flex: 0;
      min-width: 1rem;
    }
  }
`;

export const OptionGroup = styled.div<{ $cols?: number }>`
  display: grid !important;
  width: auto !important;
  grid-template-columns: ${(props) =>
    Array(props.$cols || 1)
      .fill('1fr')
      .join(' ')};
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-gap: 10px 20px;
  padding: 20px 0;
`;
